import React, { FC } from "react";
import { Modal } from "antd";
import { createPortal } from "react-dom";
import "./style.scss";
interface ModalDefaultToolProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSetDefaultTool: () => void;
}
export const ModalDefaultTool: FC<ModalDefaultToolProps> = ({
  isOpen,
  handleClose,
  handleSetDefaultTool,
}) => {
  return (
    <>
      {createPortal(
        <Modal
          title={[<div>Default Tool</div>]}
          centered
          open={isOpen}
          onOk={handleSetDefaultTool}
          onCancel={handleClose}
          cancelText={"No, thanks"}
          okText={"Yes, make it default"}
          destroyOnClose={true}
          className={"default-tool-modal"}
        >
          <h2 style={{ margin: "0" }}>
            Would you like to make Playground your default tool?
          </h2>
          <p>
            {" "}
            If you choose “yes” next time you sign in, you will automatically be
            brought into Playground as your default tool of choice.
          </p>
        </Modal>,
        document.getElementById("root"),
      )}
    </>
  );
};
