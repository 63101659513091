export const validateMessages = {
  required: "${label} is required",
  types: {
    email: "${label} is not a valid email",
    number: "${label} is not a valid number",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export const ADVISOR_CONTENT = [
  "Project Identification and Scope",
  "Feasibility and Site Assessment",
  "Financial and Economic Analysis",
  "Regulatory and Compliance",
  "Project Implementation and Timeline",
  "Operations Maintenance and Monitoring",
];

export const THEORY_OF_CHANGE_CONTENT = [
  "Goal Statement Review",
  "Log-frame Review/IRMF",
  "Barriers and Risks Review",
  "Assumptions Validation",
  "Enhanced Log-frame",
];

export const RATIONAL_ADVISOR_CONTENT = [
  "Identification",
  "Response",
  "National Alignment",
  "Monitoring and Evaluation",
];

export const RATIONAL_ADVISOR_SUBTABS = {
  Identification: ["1.1 Identification (Cross-cutting)"],
  //Response: ["2.1 Potential Barriers"],
  Response: [],
  "National Alignment": [],
  "Monitoring and Evaluation": [],
};

export const EVALUATE_CONTENT = [
  "B.1 Context and Baseline",
  "B.2 Project/Programme Description",
  "B.3 Expected Results: Impact & Paradigm Shift",
  "B.4 Engagement Among Stakeholders",
  "C.1 Indicative Finance",
  "C.2 Justification of GCF Funding Request",
  "C.3 Sustainability and Replicability",
];

export const EVALUATE_SUBTABS = {
  "B.1 Context and Baseline": [
    "B.1.1 Project Targeting",
    "B.1.2 National Alignment",
    "B.1.3 Root Causes and Barriers",
  ],
  "B.2 Project/Programme Description": [
    "B.2.1 Theory of Change",
    "B.2.2 Accredited Entity and Project Monitoring",
  ],
  "B.3 Expected Results: Impact & Paradigm Shift": [
    "B.3.1 Sustainable Development Potential",
    "B.3.2 Needs, Ownership & Efficiency Potential",
  ],
  "B.4 Engagement Among Stakeholders": [],
  "C.1 Indicative Finance": [],
  "C.2 Justification of GCF Funding Request": [],
  "C.3 Sustainability and Replicability": [],
  //'C.3 Sustainability and Replicability': ['C.3.1 Needs of Community'],
};

export const PROJECT_CONTENT = {
  "context-and-baseline-form": {
    percent: "0%",
    conceptNoteSection: "B.1 Context and Baseline",
  },
  "context-and-baseline-results": {
    percent: "8%",
    conceptNoteSection: "B.1 Context and Baseline",
  },
  "project-description-form": {
    percent: "8%",
    conceptNoteSection: "B.2 Project Description",
  },
  "project-description-results": {
    percent: "16%",
    conceptNoteSection: "B.2 Project Description",
  },
  "project-results-gcf-form": {
    percent: "16%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results": {
    percent: "24%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-results-gcf-form-24": {
    percent: "24%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results-32": {
    percent: "32%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-results-gcf-form-32": {
    percent: "32%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results-40": {
    percent: "40%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-results-gcf-form-40": {
    percent: "40%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results-48": {
    percent: "48%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-results-gcf-form-48": {
    percent: "48%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results-56": {
    percent: "56%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-results-gcf-form-56": {
    percent: "56%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "project-result-gcf-results-64": {
    percent: "64%",
    conceptNoteSection: "B.3 Expected Project Results",
  },
  "nda-ae-form-64": {
    percent: "64%",
    conceptNoteSection: "B.4 Engagement Among Stakeholders",
  },
  "gcf-justification-form-72": {
    percent: "72%",
    conceptNoteSection: "C.2 Justification of Funding",
  },
  "nda-ae-results-72": {
    percent: "72%",
    conceptNoteSection: "B.4 Engagement Among Stakeholders",
  },
  "gcf-justification-results-90": {
    conceptNoteSection: "C.2 Justification of Funding",
    percent: "90%",
  },
  "sustainability-replicability-form-90": {
    percent: "100%",
    conceptNoteSection: "C.3 Sustainability and Replicability",
  },
  "sustainability-replicability-results-100": {
    percent: "100%",
    conceptNoteSection: "C.3 Sustainability and Replicability",
  },
};

export const RATIONAL_ADVISOR_PERCENTAGE = [
  "24%",
  "48%",
  "64%",
  "76%",
  "84%",
  "100%",
];
export const THEORY_OF_CHANGE_PERCENTAGE = ["24%", "48%", "64%", "76%", "100%"];
export const EVALUATE_PERCENTAGE = [
  "0%",
  "8%",
  "14%",
  "21%",
  "29%",
  "34%",
  "43%",
  "50%",
  "57%",
  "64%",
  "71%",
  "78%",
  "85%",
  "92%",
  "100%",
];
