import { Button, Form, Input, Spin } from "antd";
import { observer } from "mobx-react";
import React, { memo, useState } from "react";
import style from "./style.module.scss";
import welcomeLogo from "@assets/images/Janus-logo-black.png";
import warning from "@assets/icons/warning.svg";
import Eye from "@assets/icons/Eye.png";
import EyeOff from "@assets/icons/EyeOff.png";
import { validateMessages } from "@utils/json-data";
import { useStore } from "@stores/root-store";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import InfoBanner from "@components/common-components/info-banner/InfoBanner";

const Login = observer(() => {
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const [open, setIsOpen] = useState(true);

  const {
    user: { onUserLogin, isLoadingLogin },
  } = useStore(null);

  const onLogin = (value) => {
    onUserLogin({ ...value, email: value.email.toLocaleLowerCase() }, () => {
      loginForm.resetFields(["password"]);
    })
      .then((res) => {
        const { success, nav } = res;

        if (success) {
          switch (nav) {
            case "home":
              navigate(`${constRoute.home}`);
              break;
            case "playground":
              navigate("/playground/form/1");
              break;
            default:
              navigate(`${constRoute.home}`);
              break;
          }
        } else {
          navigate(`${constRoute.login}`);
        }
      })
      .catch(() => {
        console.log("catch");
        navigate(`${constRoute.login}`);
      });
  };

  return (
    <div className={style.mainLoginWrraper}>
      {/* <InfoBanner open={open} setIsOpen={(val) => setIsOpen(val)} /> */}
      {/*<div className={style.maintenance}>*/}
      {/*  <img src={warning} alt="Warning logo" />*/}
      {/*  <h3 className={style.maintenanceHeading}>*/}
      {/*    Scheduled Maintenance In Progress: 10 Jan, 2025 10:00AM - 3:00PM EST*/}
      {/*    (3:00PM - 8:00PM GMT)*/}
      {/*  </h3>*/}
      {/*  <p>*/}
      {/*    To enhance the performance of Climate Finance Copilot, we have*/}
      {/*    scheduled a few hours of maintenance. During this period, we will*/}
      {/*    integrate new functionalities and resolve technical bugs within our*/}
      {/*    main tools. We apologize for any inconvenience this may cause and*/}
      {/*    appreciate your understanding.*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div
        style={{ width: 500, margin: 20 }}
        // className={classNames(open && style.mainLoginWrraperText)}
      >
        <div className={style.welcomeWrraper}>
          <img src={welcomeLogo} alt="welcom logo" />
          <h2>Welcome Back</h2>
        </div>
        <Form
          form={loginForm}
          name={"basic"}
          onFinish={onLogin}
          autoComplete={"off"}
          validateMessages={validateMessages}
          className={style.loginForm}
          layout="vertical"
        >
          <Form.Item
            label={"Email Address"}
            name={"email"}
            rules={[
              {
                required: true,
                type: "email",
                message: `Please provide a valid email address`,
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          <Form.Item
            label={"Password"}
            name={"password"}
            rules={[
              {
                required: true,
                message: "Invalid password",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter Password"
              className={style.passwordInput}
              iconRender={(visible) =>
                visible ? (
                  <img height={18} width={18} src={Eye} alt="" />
                ) : (
                  <img height={18} width={18} src={EyeOff} alt="" />
                )
              }
            />
          </Form.Item>
          <div>
            <div className={style.loginWrraper}>
              <p
                onClick={() => {
                  navigate(constRoute.forgetPassword);
                }}
              >
                Forgot Password?
              </p>
              <Button className={style.loginBtn} htmlType="submit">
                {" "}
                {(isLoadingLogin && <Spin />) || "Log In"}{" "}
              </Button>
            </div>
            <div className={style.signupWrraper}>
              <p>Don’t have an account?</p>
              <span onClick={() => navigate(constRoute?.signup)}>Sign up</span>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default memo(Login);
