import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, ConfigProvider } from "antd";
import style from "./style.module.scss";
import { onLogOutClearAll } from "@utils/common-functions";
import debounce from "lodash.debounce";

const LOCAL_STORAGE_KEY = "sessionActivity";

const SessionWarningModal = () => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isModalOpenRef = useRef(false);

  const warningTime = 35 * 60 * 1000; // 35 minutes
  const logoutTime = 40 * 60 * 1000; // 40 minutes
  // const warningTime = 10 * 1000; // 10 seconds [DEBUG]
  // const logoutTime = 30 * 1000; // 30 seconds [DEBUG]

  const handleUserActivity = () => {
    if (!isModalOpenRef.current) {
      const now = Date.now();
      const activityData =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
      activityData.lastInteractionTime = now;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(activityData));
    }
  };

  const checkInactivity = () => {
    const activityData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const lastInteractionTime = activityData?.lastInteractionTime || Date.now();
    const appInactiveTime = Date.now() - lastInteractionTime;

    if (appInactiveTime >= warningTime && !isModalOpenRef.current) {
      openModal();
    }

    if (activityData?.closeModal === true && isModalOpenRef.current === true) {
      setIsModalOpen(false);
      isModalOpenRef.current = false;
      activityData.closeModal = false;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(activityData));
    }

    if (appInactiveTime >= logoutTime) {
      handleSoftLogout();
    }

    setRemainingTime(
      Math.max(0, Math.ceil((logoutTime - appInactiveTime) / 1000)),
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
    isModalOpenRef.current = true;
  };

  const closeModal = () => {
    const activityData =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    activityData.closeModal = true;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(activityData));

    setIsModalOpen(false);
    isModalOpenRef.current = false;
    handleUserActivity();
  };

  const handleLogout = () => {
    setIsModalOpen(false);
    onLogOutClearAll();
  };

  const handleSoftLogout = () => {
    setIsLoggedOut(true);
    localStorage.removeItem("sessionActivity");
    localStorage.removeItem("token");
  };

  const handleToLogin = () => {
    onLogOutClearAll("/login");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return (
      <>
        {minutes > 0 ? (
          <>
            {" "}
            <strong>
              {String(minutes).padStart(2, "0") +
                (minutes > 1 ? " mins" : " min")}
            </strong>{" "}
            and{" "}
          </>
        ) : (
          ""
        )}
        <strong>{String(remainingSeconds).padStart(2, "0")} seconds</strong>
      </>
    );
  };

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    const events = ["click", "keydown", "mousemove", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, debounce(handleUserActivity, 1000)),
    );

    const activityCheckInterval = setInterval(checkInactivity, 1000);

    const syncActivityAcrossTabs = (event) => {
      if (event.key === LOCAL_STORAGE_KEY) {
        checkInactivity();
      }
    };

    window.addEventListener("storage", debounce(syncActivityAcrossTabs, 1000));

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, debounce(handleUserActivity, 100)),
      );
      clearInterval(activityCheckInterval);
      window.removeEventListener(
        "storage",
        debounce(syncActivityAcrossTabs, 1000),
      );
    };
  }, []);

  const modalStyles = {
    header: {
      borderBottom: "1px solid #DDE2E4",
      padding: "15px",
    },
    body: {
      padding: "0 15px",
    },
    mask: {},
    footer: {
      display: "flex",
      padding: "0px 20px 20px 20px",
      marginTop: "0",
      justifyContent: isLoggedOut === false ? "center" : "space-between",
    },
    content: {
      padding: "0",
      width: "570px",
    },
  };

  return (
    <ConfigProvider
      modal={{
        styles: modalStyles,
      }}
    >
      {isLoggedOut === false ? (
        <Modal
          open={isModalOpen}
          className={style.modal}
          onOk={closeModal}
          onClose={closeModal}
          onCancel={closeModal}
          centered
          title={[
            <div className={style.modalHeader}>Session Timeout Warning</div>,
          ]}
          footer={[
            <Button
              style={{
                backgroundColor: "#E6E6E6",
                color: "#808080",
                height: "44px",
                width: "250px",
                borderRadius: "5px",
              }}
              key="logout"
              type="primary"
              onClick={handleLogout}
            >
              Log Out
            </Button>,
            <Button
              style={{
                backgroundColor: "#00B840",
                height: "44px",
                width: "250px",
                borderRadius: "5px",
              }}
              key="continue"
              type="primary"
              onClick={closeModal}
            >
              Continue Session
            </Button>,
          ]}
        >
          <div>
            <h2 style={{ margin: "0" }}>Are you still there?</h2>
            <p>Your session is about to expire due to inactivity.</p>
            <p>You will be logged out in {formatTime(remainingTime)}.</p>
          </div>
        </Modal>
      ) : (
        <Modal
          open={isModalOpen}
          className={style.modal}
          onOk={handleToLogin}
          onClose={handleToLogin}
          onCancel={handleToLogin}
          centered
          title={[
            <div className={style.modalHeader}>Session Timeout Warning</div>,
          ]}
          footer={[
            <Button
              style={{
                backgroundColor: "#00B840",
                height: "44px",
                width: "100%",
                borderRadius: "5px",
              }}
              key="continue"
              type="primary"
              onClick={handleToLogin}
            >
              Log In
            </Button>,
          ]}
        >
          <div>
            <h2 style={{ margin: "0" }}>Session Timeout!</h2>
            <p>You have been logged out due to inactivity.</p>
          </div>
        </Modal>
      )}
    </ConfigProvider>
  );
};

export default SessionWarningModal;
