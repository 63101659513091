import { flow, types } from "mobx-state-tree";
import { masterAdminApi, userApi } from "../../api";
import { notification } from "../../utils/notifications";
import { constRoute } from "@utils/route";
import { catchError, onLogOutClearAll } from "@utils/common-functions";
import {
  allUsersType,
  currentUserModel,
  getProjectModel,
  userInfoModel,
} from "@stores/store-utils";
import { toJS } from "mobx";

const userType = types.enumeration("UserType", [
  "basic",
  "premium",
  "enterprise",
]);

const usersType = {
  [`account holders`]: [],
  [`total users`]: 0,
};

export const user = types

  .model({
    userInfo: types.maybeNull(userInfoModel),
    loading: types.optional(types.boolean, false),
    loadingLogin: types.optional(types.boolean, false),
    loadingSignup: types.optional(types.boolean, false),
    loadingResendEmail: types.optional(types.boolean, false),
    loadingResetPassword: types.optional(types.boolean, false),
    loadingEmailVerification: types.optional(types.boolean, false),
    loadingMasterAdmin: types.optional(types.boolean, false),
    verificationCode: types.maybeNull(types.string),
    loadingProjectSave: types.optional(types.boolean, false),
    projectNameData: types.maybeNull(types.string),
    projectData: types.maybeNull(types.string),
    loadingCurrentUser: types.optional(types.boolean, false),
    currentUserData: types.maybeNull(currentUserModel),
    loadingConceptNote: types.optional(types.boolean, false),
    loadExistingProject: types.optional(types.boolean, false),
    loadingDeleteRecord: types.optional(types.boolean, false),
    loadingProjectData: types.optional(types.boolean, false),
    projectList: types.maybeNull(types.string),
    loadingGenerateReport: types.optional(types.boolean, false),
    getProjectData: types.maybeNull(getProjectModel),
    loadingSingleProjectData: types.optional(types.boolean, false),
    loadingGetCongratulationResults: types.optional(types.boolean, false),
    loadingGetAdvisorResults: types.optional(types.boolean, false),
    conceptNoteData: types.maybeNull(types.string),
    tokens_used: types.optional(types.string, ""),
    userType: types.optional(userType, "premium"),
    allUsers: types.optional(allUsersType, {}),
  })
  .views((self) => ({
    get getUserType() {
      return toJS(self.userType);
    },
    get getTokensInfo() {
      return toJS(self.tokens_used);
    },
    get getAllUsers() {
      return toJS(self.allUsers);
    },
    get getUserInfo() {
      return toJS(self.userInfo);
    },
    get isLoadingLogin() {
      return toJS(self.loadingLogin);
    },
    get isLoadingSignup() {
      return toJS(self.loadingSignup);
    },
    get isLoadingResendEmail() {
      return toJS(self.loadingResendEmail);
    },
    get isLoadingResetPassword() {
      return toJS(self.loadingResetPassword);
    },
    get isLoadingEmailVerification() {
      return toJS(self.loadingEmailVerification);
    },
    get isLoadingMasterAdmin() {
      return toJS(self.loadingMasterAdmin);
    },
    get isLoadingProjectSave() {
      return toJS(self.loadingProjectSave);
    },
    get getProjectNameData() {
      return toJS(self.projectNameData);
    },
    get getCurrentUserData() {
      return toJS(self.currentUserData);
    },
    get getLoadingConceptNote() {
      return toJS(self.loadingConceptNote);
    },
    get getLoadingDeleteRecord() {
      return toJS(self.loadingDeleteRecord);
    },
    get getProjectListData() {
      return toJS(self.projectList);
    },
    get getLoadingExistingProject() {
      return toJS(self.loadExistingProject);
    },
    get getLoadingGenerateReport() {
      return toJS(self.loadingGenerateReport);
    },
    get getProjectDataList() {
      return toJS(self.getProjectData);
    },
    get getLoadingGetProject() {
      return toJS(self.loadingSingleProjectData);
    },
    get getconceptNotedataList() {
      return toJS(self.conceptNoteData);
    },
  }))
  .actions((self) => {
    const onSuperAdminLogin = flow(function* (data, navigate, resetPassword) {
      self.loadingLogin = true;
      try {
        const res = yield masterAdminApi.login(data);
        localStorage.setItem("master_token", res?.jwt_token);
        localStorage.setItem("master_email", data?.email);

        if (res?.jwt_token) {
          loadMasterAdminInfo().then((data) => {
            if (
              data?.data?.error?.includes("Invalid token") ||
              data?.data?.error?.includes("Token has expired")
            ) {
              catchError(data, "loadUserInfo");
              navigate(`${constRoute.masteradminLogin}`);
            } else {
              notification.success("Signed in successfully");
              localStorage.setItem(
                "master_userData",
                JSON.stringify({
                  name: res.name,
                  email: res.email,
                }),
              );
              navigate(`${constRoute.masteradminDashboard}`);
              localStorage.setItem("superadmin", "superadmin");
            }
          });
        }
      } catch (error) {
        resetPassword();
        catchError(error, "onUserLogin");
      } finally {
        self.loadingLogin = false;
      }
    });

    const receiveAllUsers = flow(function* () {
      let response;
      try {
        const res = yield masterAdminApi.receiveAllUsers();
        if (res) {
          self.allUsers = res;
        }
        response = res;
      } catch (error) {
        catchError(error, "not users");
      } finally {
        return response;
      }
    });

    const onDeleteUser = flow(function* (email) {
      let response;

      try {
        response = yield masterAdminApi.deleteUserByEmail(email);
      } catch (error) {
        catchError(error, "not deleted");
      } finally {
        return response;
      }
    });

    const onUpdateUserPlan = flow(function* ({ email, new_plan }) {
      let response;

      try {
        response = yield masterAdminApi.updateUserPlan({ email, new_plan });
      } catch (error) {
        catchError(error, "not update");
      } finally {
        return response;
      }
    });

    const onDowngradeUserPlan = flow(function* ({ email, new_plan }) {
      let response;

      try {
        response = yield masterAdminApi.downgradeUserPlan({ email, new_plan });
      } catch (error) {
        catchError(error, "not downgrade");
      } finally {
        return response;
      }
    });

    const addSubAccounts = flow(function* ({ email, subaccount_email }) {
      let response;

      try {
        response = yield masterAdminApi.addUsersToAccount({
          email,
          subaccount_email,
        });
      } catch (error) {
        catchError(error, "not added");
      } finally {
        return response;
      }
    });

    const addPurchasedTokens = flow(function* ({
      email,
      new_purchased_tokens,
    }) {
      let response;

      try {
        response = yield masterAdminApi.purchaseTokens({
          email,
          new_purchased_tokens,
        });
      } catch (error) {
        catchError(error, "not added");
      } finally {
        return response;
      }
    });

    const handleGetDashboardData = flow(function* () {
      let response;

      try {
        response = yield masterAdminApi.getDashboardData();
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleGetFiles = flow(function* (data) {
      let response;

      try {
        response = yield masterAdminApi.getFiles(data);
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleGetFileInfo = flow(function* (data) {
      let response;

      try {
        response = yield masterAdminApi.getFileInfo(data);
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleDeleteFile = flow(function* (data) {
      let response;

      try {
        response = yield masterAdminApi.removeFile(data);
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleCreateFile = flow(function* (data) {
      let response;

      try {
        response = yield masterAdminApi.createFile(data);
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleGetGuidePdf = flow(function* () {
      let response;

      try {
        response = yield userApi.getPromptGuidePdf();
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const handleCheckInputTokensValid = flow(function* (data) {
      let response;

      try {
        response = yield userApi.checkInputTokensValid(data);
      } catch (error) {
        catchError(error, "not found");
      } finally {
        return response;
      }
    });

    const onUserLogin = flow(function* (data, resetPassword) {
      self.loadingLogin = true;
      try {
        const res = yield userApi.onUserLogin(data);
        localStorage.setItem("token", res?.jwt_token);
        localStorage.setItem("email", data?.email);
        localStorage.setItem("free_trial_left", res["free trial left"]);

        const defaultTool = res["default_tool"];
        if (defaultTool !== null) {
          localStorage.setItem("defaultTool", defaultTool);
        }

        localStorage.setItem("plan", res?.plan?.toLowerCase());
        setUserType(res?.plan?.toLowerCase() || "premium");

        console.log("res?.jwt_token", res?.jwt_token);

        if (res?.jwt_token) {
          loadUserInfo().then((data) => {
            console.log("data", data);
            if (
              data?.data?.error?.includes("Invalid token") ||
              data?.data?.error?.includes("Token has expired")
            ) {
              catchError(data, "loadUserInfo");
            } else {
              notification.success("Signed in successfully");
              localStorage.setItem(
                "userData",
                JSON.stringify({
                  firstname: res.firstname,
                  lastname: res.lastname,
                  email: res.email,
                }),
              );
            }
          });
        }

        return {
          success: true,
          nav: defaultTool,
        };
      } catch (error) {
        console.log("error", error);
        resetPassword();
        catchError(error, "onUserLogin");
        return {
          success: false,
        };
      } finally {
        self.loadingLogin = false;
      }
    });

    const onSignUpUser = flow(function* (data) {
      self.loadingSignup = true;
      let response = null;
      try {
        const res = yield userApi.onSignUpUser(data);
        response = res;
        notification.success("Signed up successfully");
      } catch (error) {
        catchError(error, "onSignUpUser");
      } finally {
        self.loadingSignup = false;
        return response;
      }
    });

    const setUserType = flow(function* (newType) {
      self.userType = newType;
    });

    const setAllUsers = flow(function* (users) {
      self.allUsers = users;
    });

    const onSendResendEmail = flow(function* (data) {
      self.loadingResendEmail = true;
      let response = null;
      try {
        const res = yield userApi.sendResendEmail(data);
        response = res;
        notification.success("Email resent successfully");
      } catch (error) {
        catchError(error, "onSendResendEmail");
      } finally {
        self.loadingResendEmail = false;
        return response;
      }
    });

    const onSendEmailVerification = flow(function* (data) {
      self.loadingEmailVerification = true;
      let response = null;
      try {
        const res = yield userApi.senddEmailVerification(data);
        response = res;
        self.verificationCode = response?.verification_code;
      } catch (error) {
        catchError(error, "onSendResendEmail");
      } finally {
        self.loadingEmailVerification = false;
        return response;
      }
    });

    const getUserDetails = flow(function* (data) {
      self.loadingMasterAdmin = true;
      let response = null;

      try {
        const res = yield masterAdminApi.getUserData(data);
        response = res;
        self.verificationCode = response?.verification_code;
      } catch (error) {
        catchError(error, "onSendResendEmail");
      } finally {
        self.loadingMasterAdmin = false;
        return response;
      }
    });

    const onResetPassword = flow(function* (data) {
      self.loadingResetPassword = true;
      let response = null;
      try {
        const res = yield userApi.resetPassword(data);
        response = res;
      } catch (error) {
        catchError(error, "onResetPassword");
      } finally {
        self.loadingResetPassword = false;
        return response;
      }
    });
    const projectSave = flow(function* (data, navigate = "null") {
      self.loadingProjectSave = true;
      let response = null;
      try {
        const res = yield userApi.onProjectSave(data);
        if (res?.message?.includes("project saved successfully")) {
          notification.success("Project saved successfully");
          if (typeof data?.project_name === "string") {
            localStorage.setItem("projectName", data?.project_name);
          }
          self.projectNameData = JSON.stringify(data);
        }
        response = res;
      } catch (error) {
        catchError(error, "projectSave");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingProjectSave = false;
        return response;
      }
    });
    const conceptNote = flow(function* (data, navigate = null) {
      self.loadingConceptNote = true;
      let response = null;
      try {
        const res = yield userApi.onConceptNote(data);
        self.conceptNoteData = res?.response;
        response = res;
      } catch (error) {
        catchError(error, "conceptNote");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingConceptNote = false;
        return response;
      }
    });

    const loadMasterAdminInfo = flow(function* (navigate = null) {
      self.loadingCurrentUser = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingCurrentUser = true;
        const res = yield masterAdminApi.getCurrentUserDetails();
        response = res;
        self.currentUserData = res;
      } catch (error) {
        catchError(error, "loadUserInfo");
        response = error.response;
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingCurrentUser = false;
        return response;
      }
    });
    const loadUserInfo = flow(function* (navigate = null) {
      self.loadingCurrentUser = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingCurrentUser = true;
        const res = yield userApi.getCurrentUserDetails();
        response = res;
        self.currentUserData = res;
      } catch (error) {
        catchError(error, "loadUserInfo");
        response = error.response;
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingCurrentUser = false;
        return response;
      }
    });
    const loadGetExistingProject = flow(function* (navigate = null) {
      self.loadExistingProject = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadExistingProject = true;
        const res = yield userApi.onGetExistingProject();
        response = res;
        const dummyArray = [];
        res?.projects["concept note"]?.forEach((item) => {
          dummyArray?.push({ projectName: item });
        });
        self.projectList = JSON.stringify(dummyArray);
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadExistingProject = false;
        return response;
      }
    });

    const projectDelete = flow(function* (data, navigate = null) {
      self.loadingDeleteRecord = true;
      let response = null;
      try {
        const res = yield userApi.onDeleteProject(data);
        if (res?.message?.includes("project deleted")) {
          notification.success("Project Deleted");
        }
        response = res;
      } catch (error) {
        catchError(error, "deleteProject");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingDeleteRecord = false;
        return response;
      }
    });

    const setTokens = flow(function* (users) {
      self.tokens_used = users;
    });

    const getProjectReportPdf = flow(function* (data, navigate = null) {
      let response = null;
      try {
        const res = yield userApi.getProjectReportPdf(data);
        response = res;
      } catch (error) {
        catchError(error, "getProjectReportPdf");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        return response;
      }
    });

    const getSingleProjectData = flow(function* (data, navigate = null) {
      self.loadingSingleProjectData = true;
      let response = null;
      try {
        const res = yield userApi.onGetData(data);
        response = res;
        self.getProjectData = res?.questions;
        self.projectData = JSON.stringify(res);
        self.tokens_used = JSON.stringify(
          response["tokens_remaining/tokens_purchased"],
        );
        localStorage.setItem(
          "tokens",
          res["tokens_remaining/tokens_purchased"],
        );
      } catch (error) {
        catchError(error, "getData");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingSingleProjectData = false;
        return response;
      }
    });
    const resetProjectData = () => {
      self.getProjectData = null;
    };
    const generateReport = flow(function* (data, navigate = null) {
      self.loadingGenerateReport = true;
      let response = null;
      try {
        const res = yield userApi.onGenerateProject(data);
        // self.getProjectData= res;
        // if(res?.message?.includes('project deleted')){
        //   notification.success('Generated Report');
        // }
        response = res;
      } catch (error) {
        catchError(error, "generateReport");
        if (
          error?.response?.data?.error?.includes("Invalid token") ||
          error?.response?.data?.error?.includes("Token has expired")
        ) {
          onLogOutClearAll("/login");
        }
      } finally {
        self.loadingGenerateReport = false;
        return response;
      }
    });
    //
    const loadGetCongratulationResults = flow(function* (data) {
      self.loadingGetCongratulationResults = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingGetCongratulationResults = true;
        const res = yield userApi.getCongratulationResults(data);
        response = res;
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
      } finally {
        self.loadingGetCongratulationResults = false;
        return response;
      }
    });

    const loadRationalAdvisorResults = flow(function* (data) {
      self.loadingGetCongratulationResults = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingGetCongratulationResults = true;
        const res = yield userApi.getRationalAdvisorResults(data);
        response = res;
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
      } finally {
        self.loadingGetCongratulationResults = false;
        return response;
      }
    });

    const loadGetDraftResults = flow(function* (data) {
      self.loadingGetCongratulationResults = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingGetCongratulationResults = true;
        const res = yield userApi.getDraftResults(data);
        response = res;
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
      } finally {
        self.loadingGetCongratulationResults = false;
        return response;
      }
    });

    const loadGetAdvisorResults = flow(function* (data) {
      self.loadingGetAdvisorResults = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingGetAdvisorResults = true;
        const res = yield userApi.getPSSummary(data);
        response = res;
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
      } finally {
        self.loadingGetAdvisorResults = false;
        return response;
      }
    });

    const loadGetTheoryOfChangeResults = flow(function* (data) {
      self.loadingGetAdvisorResults = true;
      let response = null;
      // self.currentUserData =null;
      try {
        self.loadingGetAdvisorResults = true;
        const res = yield userApi.getTCSummary(data);
        response = res;
      } catch (error) {
        catchError(error, "getProject");
        response = error.response;
      } finally {
        self.loadingGetAdvisorResults = false;
        return response;
      }
    });
    //
    const setProjectName = (projectName = "") => {
      const data = {
        project_name: projectName,
      };
      self.projectNameData = JSON?.stringify(data);
    };
    const setConceptNoteLoading = (value) => {
      self.loadingConceptNote = value;
    };
    return {
      setUserType,
      onUserLogin,
      onSignUpUser,
      loadUserInfo,
      onSendResendEmail,
      onResetPassword,
      onSendEmailVerification,
      projectSave,
      conceptNote,
      loadGetExistingProject,
      loadGetCongratulationResults,
      loadGetDraftResults,
      projectDelete,
      generateReport,
      getSingleProjectData,
      setProjectName,
      resetProjectData,
      setConceptNoteLoading,
      loadGetAdvisorResults,
      onSuperAdminLogin,
      receiveAllUsers,
      onDeleteUser,
      onUpdateUserPlan,
      setAllUsers,
      onDowngradeUserPlan,
      getUserDetails,
      getProjectReportPdf,
      addSubAccounts,
      loadMasterAdminInfo,
      handleGetDashboardData,
      setTokens,
      loadGetTheoryOfChangeResults,
      handleGetGuidePdf,
      handleCheckInputTokensValid,
      addPurchasedTokens,
      loadRationalAdvisorResults,
      handleGetFiles,
      handleCreateFile,
      handleGetFileInfo,
      handleDeleteFile,
    };
  });

export function initUser() {
  return user.create({});
}
