import { getAuthorizationHeader } from "../common-utils";
import axios from "axios";

import { BaseApi } from "../baseApi";
import { setDefaultToolUrl } from "@api/const";

class PreferencesApi extends BaseApi {
  setDefaultTool = async (tool: string) => {
    try {
      const response = await axios.post(
        setDefaultToolUrl,
        {
          default_tool: tool,
        },
        {
          headers: { Authorization: getAuthorizationHeader() },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default PreferencesApi;
