import { MenuOutlined } from "@ant-design/icons";
import { userApi } from "@api";
import SoftwareIcon from "@assets/icons/SoftwareIcon";
import UpgradeIcon from "@assets/icons/UpgradeIcon";
import LogoutIcon from "@assets/icons/log-out.svg";
import welcomeLogo from "@assets/images/Janus-logo-black.png";
import { useUpgradePlanModal } from "@components/ModalContext/ModalContext";
import EmailReport from "@components/layout/main-layout/private-layout/header/email-report";
import { resetStore, useStore } from "@stores/root-store";
import useWindowSize from "@utils/hooks/useWindowSize";
import { constImages } from "@utils/images";
import { constRoute } from "@utils/route";
import type { MenuProps } from "antd";
import { Dropdown, Menu, Row, Select, Space } from "antd";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import basicIcon from "../../../../../assets/icons/basic-icon.svg";
import enterpriseIcon from "../../../../../assets/icons/enterprise-icon.svg";
import premiumIcon from "../../../../../assets/icons/premium-icon.svg";
import style from "./style.module.scss";

const Header = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const showMailDesc = useMediaQuery({ query: "(min-width: 1225px)" });
  const showHeaderButtons = useMediaQuery({ query: "(min-width: 912px)" });

  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [isFaqModel, setIsFaqModel] = useState(false);
  const [openTermModel, setOpenTermModel] = useState(false);
  const [isTermShow, setIsTermShow] = useState(false);
  const [isTermModel, setIsTermmodel] = useState(false);
  const [current, setCurrent] = useState(
    localStorage.getItem("currentPage") || "/home",
  );

  const {
    user: { loadUserInfo, getCurrentUserData, userType },
  } = useStore(null);
  const { openModal } = useUpgradePlanModal();

  const userTypePill = (() => {
    switch (userType) {
      case "basic":
        return <img src={basicIcon} alt="basic" />;
      case "premium":
        return <img src={premiumIcon} alt="premium" />;
      case "enterprise":
        return <img src={enterpriseIcon} alt="enterprise" />;
      default:
        return <div className={style.userType}>{userType}</div>;
    }
  })();

  const onLogout = () => {
    resetStore();
    localStorage.removeItem("AllAnswers");
    localStorage.removeItem("token");
    localStorage.removeItem("theme");
    localStorage.removeItem("email");
    localStorage.removeItem("userData");
    localStorage.removeItem("defaultTool");
    localStorage.removeItem("playgroundUseCount");
    navigate(constRoute.login);
  };

  const data = useWindowSize().width;

  useEffect(() => {
    if (data < 912) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [data]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate(constRoute.login);
    }
  }, []);

  const items: MenuProps["items"] = [
    {
      label: "Home",
      key: "/home",
    },
    {
      label: "Projects",
      key: "/myProjects",
    },
    {
      label: "Playground",
      key: "/playground/form/1",
    },
    {
      label: "FAQs",
      key: "/FAQs",
    },
    {
      label: "User Guide",
      key: "user-guide",
    },
  ];

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    await userApi.getCurrentUserDetails().then((data) => {
      setUserData(data);
      localStorage.setItem("email", data.email);
    });
  };

  const getUserGuide = async () => {
    await userApi.getUserGuide().then((blob) => {
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");

      // Clean up the Object URL after 5 seconds
      setTimeout(() => URL.revokeObjectURL(url), 5000);
    });
  };

  useEffect(() => {
    if (location?.pathname === "/myProjects") setCurrent("/myProjects");
    else if (location?.pathname === "/FAQs") setCurrent("/FAQs");
    else if (
      location?.pathname === "/playground/form/1" ||
      location?.pathname === "/playground/results/1"
    )
      setCurrent("/playground/form/1");
    else if (location?.pathname === "/getExpertHelp")
      setCurrent("/getExpertHelp");
    else if (location?.pathname === "/home") setCurrent("/home");
    else if (location?.pathname === "/select-function") setCurrent("/home");
    else setCurrent("/");
  }, [location?.pathname, localStorage.getItem("currentPage"), isFaqModel]);

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "/playground/form/1") {
      const defaultTool = localStorage.getItem("defaultTool");

      if (!defaultTool) {
        const storedCount = parseInt(
          localStorage.getItem("playgroundUseCount") || "0",
          10,
        );

        const newCount = storedCount + 1;
        localStorage.setItem("playgroundUseCount", newCount.toString());
      }

      window.open(`/#${e.key}`, "_blank", "noopener,noreferrer");
    } else if (e.key === "user-guide") getUserGuide();
    else {
      localStorage?.setItem("currentPage", e?.key);
      setCurrent(e.key);
      navigate(e.key);
    }
  };
  const faqModelCloe = () => {
    setIsFaqModel(false);
  };

  const dropdownMenu = (
    <div className={style.profileDropDonwMenu}>
      <div className={style.userData}>
        <div className={style.userImage}>
          {userData?.name &&
            `${userData?.name.split(" ")[0][0].toUpperCase() || ""}${
              (userData?.name.split(" ").length > 1 &&
                userData?.name.split(" ")[1][0].toUpperCase()) ||
              ""
            }`}
        </div>
        <div className={style.userDataWrapper}>
          <span className={style.userName}>
            {`${(userData?.firstname && userData?.firstname) || ""}  ${
              (userData?.lastname && userData?.lastname) || ""
            }`}
            {userTypePill}
          </span>
          <p>{userData?.email || ""}</p>
        </div>
      </div>

      <Menu>
        <Menu.Item>
          <img src="/imgs/termsSvg.svg" alt="img" />
          <Link to={`${constRoute.TermOfUse}`} rel="noreferrer">
            Terms
          </Link>
        </Menu.Item>
        <Menu.Item>
          <img src="/imgs/lockSvg.svg" alt="img" />
          <Link to={`${constRoute.setting}`} rel="noreferrer">
            Privacy Policy
          </Link>
        </Menu.Item>

        <Menu.Item>
          <SoftwareIcon />
          <Link to={`${constRoute.softwareAttribution}`} rel="noreferrer">
            Software Attribution
          </Link>
        </Menu.Item>

        {/* <Menu.Item>
          <UpgradeIcon />
          <Link to={`${constRoute.pricing}`} rel="noreferrer">
            Upgrade Plan
          </Link>
        </Menu.Item>

        {/*<Select*/}
        {/*  defaultValue={getUserType}*/}
        {/*  placeholder="Select user type"*/}
        {/*  style={{ width: "100%" }}*/}
        {/*  onChange={(val) => {*/}
        {/*    setUserType(val);*/}
        {/*  }}*/}
        {/*  options={[*/}
        {/*    { value: "basic", label: "Basic" },*/}
        {/*    { value: "premium", label: "Premium" },*/}
        {/*    { value: "enterprise", label: "Enterprise" },*/}
        {/*  ]}*/}
        {/*/>*/}
        <Menu.Item className={style.itemWithImgIcon} onClick={onLogout}>
          <img src={LogoutIcon} alt="logout" />
          Log out
        </Menu.Item>
      </Menu>
    </div>
  );
  const closeTermModel = () => {
    setOpenTermModel(false);
  };

  const styles = { background: `linear-gradient(to right,#00c5fb, 0%, 100%)` };

  const menuMemoized = useMemo(
    () =>
      !collapsed && (
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode={data > 912 ? "horizontal" : "vertical"}
            className={style.menuHeader}
            inlineCollapsed={false}
            overflowedIndicator={null}
            items={items}
          />
        </div>
      ),
    [collapsed, items],
  );

  return (
    <div className={style.topHeaderBar}>
      <div
        className={style.headerContainer}
        style={{ right: "0px", ...styles }}
      >
        <div>
          <div className={style.headerMenuContainer}>
            {(data < 912 || collapsed) && (
              <MenuOutlined
                onClick={() => setCollapsed(!collapsed)}
                className={style.menuOutlinedIcon}
              />
            )}
            <Link
              className={style.welcomeText}
              to={constRoute?.home}
              onClick={() => {
                setCurrent("/home");
              }}
            >
              <img src={welcomeLogo} alt="logo" />
            </Link>
          </div>
          {menuMemoized}
        </div>
        <ul className={style.rightMenuHeader}>
          {(showHeaderButtons || collapsed) && (
            <>
              <li>
                <EmailReport style={style} showAll={showMailDesc} />
              </li>
              <li style={{ display: "none" }}>
                <span
                  className={style.upgradeButton}
                  onClick={() => {} /*navigate("/pricing") */}
                >
                  Upgrade
                </span>
              </li>
            </>
          )}
          <li className={style.userProfileDropDownContainer}>
            <Row className={style.userProfileDropDownWrapper}>
              {/* <CustomButton
                title="Add billing info"
                className={style.billingInfo} 
              /> */}
              {/*<a href={'mailto:oliverbarrett@janusadvisoryinc.com'} target={'_blank'} className={style.billingInfoButton}>Upgrade</a>*/}
              {/*<img src={constImages.addBillingBtn} alt="" />*/}
              <Dropdown
                overlay={dropdownMenu}
                trigger={["click"]}
                placement={"bottomLeft"}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space className={style.spaceUserProfile}>
                    <span className={style.profileUserNameText}>
                      Hi,{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {" "}
                        {userData?.name || " "}
                      </span>
                    </span>
                    <span className="mobile">
                      <i className="fa fa-ellipsis-v" />
                    </span>
                    {/* <DownOutlined className={style.profileUserNameText} /> */}
                    <img
                      style={{ height: 26, width: 26 }}
                      src={constImages.dropDownIcon}
                      alt=""
                    />
                  </Space>
                </a>
              </Dropdown>
            </Row>
          </li>
        </ul>
      </div>
      {/* <FaqModel isOpen={isFaqModel} closeModal={faqModelCloe}/> */}
      {/* <TermPrivcyPolicyModel isOpen={openTermModel} closeModal={closeTermModel} isTerm={isTermShow}/> */}
    </div>
  );
});

export default Header;
