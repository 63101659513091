import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {
  useNarrativesContext,
  useTokensContext,
} from "@components/layout/main-layout/private-layout";
import AdvisorForm from "@components/pages/project/common-components/advisor-form";
import CommonFooterButton from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/commonfooterbutton";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import { baseSocketUrl } from "@api/const";
import { notification } from "@utils/notifications";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: (
      <span>
        Enter the Project’s <i>Goal Statement Review</i> Below:
      </span>
    ),
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Guidance Below:\n\n" +
      'Define your project or program\'s goal and long-term vision. Your goal statement should follow this structure: "IF [we do this]... THEN [this will happen]... BECAUSE [of this reason]."\n\n' +
      "Climate Finance Copilot will:\n\n" +
      " • Assess your goal statement for clarity, completeness, and alignment with GCF criteria.\n\n" +
      " • Provide specific suggestions for improvement if needed.",
  },
  {
    q: (
      <>
        Enter  Project’s <i>Log-frame Review/IRMF</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance:\n\n" +
      "To ensure your project aligns with GCF guidelines, please provide:\n\n" +
      "Goal Statement:  A concise statement of your project's long-term impact.\n\n" +
      'Logical Framework ("Log frame"):  A table outlining your project\'s Goal, Outcomes, Outputs, Activities, Indicators, Means of Verification, and Assumptions. Ensure your outcomes are SMART (Specific, Measurable, Achievable, Relevant, Time-bound).\n\n' +
      "Climate Finance Copilot will:\n\n" +
      " • Verify alignment between your goal and outcomes.\n\n" +
      " • Identify any missing outputs or activities.\n\n" +
      " • Match each outcome to the relevant GCF result area(s).",
  },
  {
    q: (
      <>
        Enter the Project’s <i>Barriers and Risks Review</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    placeholder:
      "Guidance ​Below:\n\n" +
      "Please describe any potential barriers and risks that your project might face.\n\n" +
      "Climate Finance Copilot will:\n\n" +
      " • Analyze your risk assessment for completeness and effectiveness.\n\n" +
      " • Suggest improvements to your risk mitigation strategies.\n\n" +
      " • Identify additional potential barriers and risks for your consideration.",
    limit: 1000,
  },
  {
    q: (
      <>
        Enter Project's <i>Assumptions Validation</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance ​​below: \n\n" +
      "Clearly identify and explain the assumptions made in the project. Climate Finance Copilot will analyze and validate the assumptions made in the proposal.",
  },
  {
    q: (
      <>
        Enter Project's <i>Enhanced Log-frame</i> Below:{" "}
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder: [
      "Guidance below: \n\n",
      "Enhance your logframe by incorporating the outputs from the previous sections.\n\n",
      "Start with your project's overarching goal and its forecasted long-term impact. Then, define the main components of your project and their expected outcomes.\n\n",
      "Climate Finance Copilot will:\n\n",
      " • Suggest additional outputs/activities to enhance your logframe.\n\n",
      " • Ensure clear linkages between components, outcomes, and outputs/activities.\n\n",
      " • Strengthen the logical flow by rewriting any weak or unclear connections.",
    ].join(""),
  },
];

export const sectionsMap = [
  {
    text: "Goal Statement Review",
    section: "GSR",
  },
  {
    text: "Log-frame Review/IRMF",
    section: "AAC",
  },
  {
    text: "Barriers and Risks Review",
    section: "BR",
  },
  {
    text: "Assumptions Validation",
    section: "A",
  },
  {
    text: "Enhanced Log-frame",
    section: "NLF",
  },
];

const sections = sectionsMap.map((section) => section.section);
//const sections = ["GSR", "AAC", "BR", "A", "NLF"];

const TheoryOfChangePage: React.FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const [form] = useForm();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const { tocNarratives, setTocNarratives } = useNarrativesContext();
  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [sectionOutputPresent, setSectionOutputPresent] = useState(false);

  const results = isSubmitting
    ? ""
    : tocNarratives.find((el) => el.section === sections[+step - 1])?.res;

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  const handleSubmit = useCallback(
    async (saveOnly = false) => {
      if (!isValid) return;
      if (isFormPage) {
        setResultsData("");

        const values = form.getFieldsValue();

        if (saveOnly === true) {
          setIsSaving(true);
        } else {
          setIsSubmitting(true);
        }

        const data: DataType = {
          section: sections[+step - 1],
          input: values?.q,
          project_name: projectName,
          email: email,
          model: getSelectedModelType ?? "Titan",
        };

        if (saveOnly === true) {
          data.save_only = true;
        }

        console.log("payload body ------------->", data);

        const socket = io(baseSocketUrl, {
          transports: ["websocket", "polling"],
        });

        socketRef.current = socket;
        socketRef?.current?.emit("theoryOfChange", data);
        socketRef?.current?.on("answer", (answer) => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");

          if (
            saveOnly === true &&
            answer === "User input is saved successfully"
          ) {
            socket.disconnect();
            setIsSaving(false);
            localStorage.removeItem("isStopGeneration");
            notification.success("Project saved successfully!");
            return nav(constRoute?.existingProject);
          }

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (answer) {
            setIsAnswer(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            setIsAnswer(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }
          form.setFieldValue("q", (form?.getFieldValue("q") ?? "") + answer);
          localStorage.setItem(
            "totalResultsAdvisor" + projectName,
            JSON.stringify({
              ...results,
              [`result${step}`]: (form?.getFieldValue("q") ?? "") + answer,
            }),
          );
        });
        socket.on("disconnect", () => {
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
          setIsAnswer(false);
        });

        if (saveOnly !== true) {
          nav(constRoute?.theoryOfChangeProject + "/results/" + step);
        }
      } else {
        if (saveOnly === true) {
          setIsSaving(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setIsSaving(false);
          notification.success("Project saved successfully!");
          return nav(constRoute?.existingProject);
        }

        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/theory-of-change");
          return;
        }
        setIsSubmitting(false);
        setIsAnswer(false);
        nav(constRoute?.theoryOfChangeProject + "/form/" + (+step + 1));
      }
    },
    [type, step, getSelectedModelType],
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    setIsAnswer(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.theoryOfChangeProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/theory-of-change");
      return;
    }

    if (type === "results") {
      nav(constRoute?.theoryOfChangeProject + "/form/" + +step);
    } else {
      nav(constRoute?.theoryOfChangeProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    handleSubmit(true);
  };

  const handleViewReport = () => {
    nav(constRoute?.theoryOfChangeProject + "/results/" + +step);
  };

  const getProjectData = async () => {
    const email = localStorage.getItem("email");
    const data = {
      section: sections[+step - 1],
      project_name: projectName,
      functionality: "theory of change",
      email,
    };
    setIsLoading(true);
    setSectionOutputPresent(false);

    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    await getSingleProjectData(data, null)
      .then((state) => {
        setInitialInput(state?.questions);
        if (isFormPage) {
          form.setFieldValue("q", state?.questions.input);
        } else {
          if (!isSubmitting) {
            form.setFieldValue(`q`, state?.generated_narratives?.find(Boolean));
          }
        }
        setTokens(state["tokens_remaining/tokens_purchased"]);
        setTocNarratives((prev) =>
          prev.map((el) => {
            if (el.section === sections[+step - 1]) {
              const obj = {
                ...el,
                res:
                  state?.generated_narratives?.map((el) => el).join("") || "",
              };

              if (obj.res !== "") {
                setSectionOutputPresent(true);
              }

              return obj;
            } else {
              return el;
            }
          }),
        );
      })
      .finally(() => setIsLoading(false));
    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/theory-of-change");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
    setIsAnswer(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");
    getProjectData();

    // return () => {
    //   localStorage.removeItem("isStopGeneration");
    // };
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setIsAnswer(false);
    setResultsData(
      tocNarratives.find((el) => el.section === sections[+step - 1])?.res || "",
    );
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <AdvisorForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        isItalicFontStyle
        section={sections[+step - 1]}
        setIsValid={setIsValid}
        isValid={isValid}
        handleStopGenerate={handleStopGenerate}
        handleViewReport={handleViewReport}
        sectionOutputPresent={sectionOutputPresent}
        isLoading={isLoading}
        isAnswer={isAnswer}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        isSaving={isSaving}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(TheoryOfChangePage));
